import { graphql, useStaticQuery } from 'gatsby';
import React, {useMemo} from 'react';
import Image from 'gatsby-image';

/* NOTE: Possible serious Gatsby performance issue
   DO NOT use this component all over the place.
   EACH instance of this component queries ALL images
   so if the site has alot of images and or Avatar components
   The build time for the site will get astronomical real quick!
   Eventually when this isuues is resolved:
   https://github.com/gatsbyjs/gatsby/issues/10482
   We can get rid of the all image dynamic static query hack.
*/

export const Avatar = ({src}) => {

 /* Dynamic static query hack, part 1
    query all images using longhand for 
    the fragment ...GatsbyImageSharpFluid_withWebp 
*/
 const data = useStaticQuery(graphql`
    {
      allFile(filter: {internal: {mediaType: {regex: "/image/"}}}) {
        nodes {
          relativePath
          childImageSharp {
            fluid(maxWidth: 300, fit: CONTAIN) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            }
          }
        }
      }
    }
  `);
  
  /* Dynamic static query hack, part 2
     Find the image we are looking for
     by sifting through every image in graphql
  */
  const match = useMemo(() => ( 
    data.allFile.nodes.find(( node ) => { 
      return src === node.relativePath;
    })
  ), [data, src])

  return match ? (<Image 
                        fluid={match.childImageSharp.fluid}
                        style={{width: '80%', /* margin: '0 auto', /*maxWidth: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center'*/}}
                        imgStyle={{display: 'block'}}
                  />) : null
};