import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import "../../content/assets/style/main.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import { Avatar } from "../components/Avatar"

class IndexPage extends React.Component {
  render() {
    const cmsData = this.props.data.allFile.edges[0].node.childMdx.frontmatter
    const hdrTitle = cmsData['hdr_title']
    const title = cmsData['title']
    const subtitle = cmsData['subtitle']

    /* NOTE: body is markdown and all markdown MUST be OUTSIDE of frontmatter 
       in order to be rendered as HTML via the <MDXRenderer>
    */
    const body = this.props.data.allFile.edges[0].node.childMdx.body
    
    const mainImage = cmsData['main_image'].split('/').pop();

    return (
      <Layout location={this.props.location} title={hdrTitle}>
        <SEO
          title="Apolo Pena"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <div>
          <Avatar src={mainImage}/>
          <br />
          <h1 className="title is-3">
            {title} {" "}
            <span role="img" aria-label="wave emoji">
              👋
            </span>
          </h1>
          <p className="subtitle is-4">
            {subtitle} 
          </p>
          <MDXRenderer>{body}</MDXRenderer>
        </div>
        <Link to="/blog/">
          <Button marginTop="35px">Go to Blog</Button>
        </Link>
      </Layout>
    )
  }
}

export default IndexPage

export const query = graphql`
  query {
    allFile(filter: {sourceInstanceName: {eq: "page data"}, name: {eq: "home"}}) {
      edges {
        node {
          id
          childMdx {
            body
            frontmatter {
              hdr_title
              main_image
              title
              subtitle
            }
          }
        }
      }
    }
  }`